<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <div class="container-fluid">
          <!-- Default box -->
          <section class="content-header">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-6">
                  <h5 class="mb-0">Tarjetones</h5>
                </div>
                <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                      <router-link to="/">Home</router-link>
                    </li>
                    <li class="breadcrumb-item">
                      <router-link to="/Hse">Hse</router-link>
                    </li>
                    <li class="breadcrumb-item active">Tarjetones</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section class="content">
            <div class="container-fluid">
              <div class="card">
                <div class="card-header pt-2 pb-2">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="btn-group float-right">
                        <button
                          type="button"
                          class="btn btn-info"
                          @click="back()"
                        >
                          <i class="fas fa-sync-alt"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body table-responsive p-0">
                  <table
                    style="font-size: 0.85em"
                    class="table table-bordered table-striped table-hover table-sm text-nowrap"
                  >
                    <thead class="text-center">
                      <tr>
                        <th>
                          Id
                          <input
                            type="number"
                            class="form-control form-control-sm p-0"
                            v-model="filters.id"
                            label="id"
                            @input="getIndex()"
                          />
                        </th>
                        <th>
                          Código
                          <input
                            type="text"
                            class="form-control form-control-sm p-0"
                            v-model="filters.codigo"
                            label="id"
                            @input="getIndex()"
                          />
                        </th>
                        <th>
                          Vehículo
                          <input
                            type="text"
                            class="form-control form-control-sm p-0"
                            v-model="filters.vehiculo"
                            label="id"
                            @input="getIndex()"
                          />
                        </th>
                        <th>
                          Solicitud Vehículo
                          <input
                            type="number"
                            class="form-control form-control-sm p-0"
                            v-model="filters.solicitud_vehiculo_id"
                            label="solicitud"
                            @input="getIndex()"
                          />
                        </th>
                        <th>Fecha</th>
                        <th>Accion</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="tarjeton in listsForms.tarjetones.data"
                        :key="tarjeton.data"
                      >
                        <td class="text-center">{{ tarjeton.id }}</td>
                        <td class="text-center" v-if="tarjeton.solicitudes_vh">
                          {{
                            tarjeton.solicitudes_vh.activo == 1
                              ? "L"
                              : tarjeton.solicitudes_vh.activo == 2
                              ? "P"
                              : tarjeton.solicitudes_vh.activo == 3
                              ? "C"
                              : "O"
                          }}-{{
                            tarjeton.solicitudes_vh.operacion_vh == 1
                              ? "INT"
                              : "EXT"
                          }}-{{ tarjeton.codigo }}
                        </td>
                        <td class="text-center" v-else></td>
                        <td
                          class="text-center"
                          v-if="tarjeton.vehiculo_id != null"
                        >
                          {{ tarjeton.vehiculo.placa }}
                          <span
                            class="info-box-text col-md-3 text-center text-success"
                            title="Se encuentra en Pegaso"
                          >
                            <i class="fas fa-check-circle"></i>
                          </span>
                        </td>
                        <td class="text-center" v-else>
                          {{ tarjeton.placa }}
                          <span
                            class="info-box-text col-md-3 text-center text-danger"
                            title="No se encuentra en Pegaso"
                          >
                            <i class="fas fa-times-circle"></i>
                          </span>
                        </td>
                        <td class="text-center">
                          {{ tarjeton.solicitud_vehiculo_id }}
                        </td>
                        <td class="text-center">{{ tarjeton.fecha }}</td>
                        <td class="text-center" v-if="tarjeton.solicitudes_vh">
                          <button
                            v-if="tarjeton.solicitudes_vh.estado == 4"
                            type="button"
                            class="btn btn-sm bg-navy"
                            @click="verTarjeton(tarjeton.solicitud_vehiculo_id)"
                          >
                            <i class="fas fa-wallet"></i>
                          </button>
                        </td>
                        <td class="text-center" v-else></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="card-footer">
                  <div class="float-left" v-if="listsForms.tarjetones">
                    <p>
                      Mostrando del <b>{{ listsForms.tarjetones.from }}</b> al
                      <b>{{ listsForms.tarjetones.to }}</b> de un total:
                      <b>{{ listsForms.tarjetones.total }}</b> Registros
                    </p>
                  </div>
                  <div class="float-left" v-else>
                    <p>
                      <span class="badge badge-danger">
                        No hay registros para mostrar
                      </span>
                    </p>
                  </div>
                  <pagination
                    :data="listsForms.tarjetones"
                    @pagination-change-page="getIndex"
                    :limit="5"
                    align="right"
                  ></pagination>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";

export default {
  name: "tarjetonDocumentosIndex",
  components: { pagination, Loading },
  data() {
    return {
      cargando: false,
      filters: {
        id: null,
        codigo: null,
      },
      form: {
        id: null,
        codigo: null,
      },
      listsForms: {
        tarjetones: {},
      },
      modal: {
        title: null,
        accion: null,
      },
      tarjeton: null,
      metodo: null,
    };
  },

  methods: {
    save() {
      this.cargando = true;
      axios({
        method: this.metodo,
        url: "/api/hse/HseTarjeton",
        data: this.form,
      })
        .then(() => {
          this.cargando = false;
          this.$refs.closeModal.click();
          this.metodo = null;
          this.$swal({
            icon: "success",
            title: "Los datos se guardaron exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      this.back();
    },

    back() {
      this.clearForm();
      this.clearFilters();
      this.getIndex();
    },

    //obtener datos
    async getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("api/hse/HseTarjetones?page=" + page, {
          params: await this.filters,
        })
        .then((response) => {
          this.cargando = false;
          this.listsForms.tarjetones = response.data;
        });
    },

    verTarjeton(solicitud) {
      this.cargando = true;
      axios({
        method: "get",
        url: "/api/hse/HseTarjeton/pdf/" + solicitud,
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          window.open(data.url, "_blank");
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    destroy(id) {
      this.$swal({
        title: "Esta seguro de eliminar este Item?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios.delete("api/hse/HseTarjeton/" + id).then((response) => {
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se elimino el item exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
        }
      });
    },

    //Limpiar filtros
    clearFilters() {
      this.filters = {
        id: null,
        codigo: null,
        solicitud_vehiculo_id: null,
      };
    },

    //Limpiar formulario
    clearForm() {
      this.form = {
        id: null,
        codigo: null,
      };
      this.metodo = null;
    },
  },

  mounted() {
    this.getIndex();
  },
};
</script>
